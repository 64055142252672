import {APIService} from "@/services/api";
import i18n from "@/plugins/i18n";

export const uniqueArticleUrlValidator = (value, field, model) => {
  return new Promise(async (resolve, reject) => {
    if (value) {
      try {
        const articles = await APIService.get(`article?filter=url||$eq||${value}`);
        if (articles.length > 0) {
          if (articles[0].id !== model.id) {
            resolve([i18n.t('UrlAlreadyExists', {url: value})]);
          }
        } else {
          resolve();
        }
      } catch (e) {
        console.error(e)
        reject()
      }
    }
  });
};
