<template>
  <crud-form
    :schema="schema"
    :model="form"
    :path="'article'"
    :form-title="$t('addArticle')"
    :redirect-route="'ArticlesList'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './schema/article';
  import { form } from './forms/article';

  export default {
    name: 'AddArticle',
    components: {
      CrudForm
    },
    data: () => {
      return {
        form,
        schema,
      }
    },
  }
</script>
