import {validators} from "vue-form-generator";
import i18n from "@/plugins/i18n";
import { uniqueArticleUrlValidator } from "../validations/uniqueArticleUrl";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "title",
      required: true,
      translation: true,
      placeholder: "name",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t('nameRequired'),
          textTooBig: i18n.t('nameMustBeLess{1}'),
        }),
      ]
    },
    {
      type: "editor",
      label: "preview",
      model: "preview",
      required: true,
      translation: true,
      placeholder: "preview",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("previewIsRequired"),
        }),
      ]
    },
    {
      type: "editor",
      label: "text",
      model: "text",
      required: true,
      translation: true,
      placeholder: "text",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("textIsRequired"),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "url",
      model: "url",
      placeholder: "url",
      max: 256,
      validator: [
        uniqueArticleUrlValidator,
        validators.string.locale({
          textTooBig: i18n.t('urlMustBeLess{1}'),
        }),
      ]
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("statusRequired"),
        }),
      ],
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
  ]
};
